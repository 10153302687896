import LanguageIdentifier from "components/languageIdentifier";
import Layout from "components/layout";
import React from "react";
import Seo from "components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Detect Language – Instant Language Detector"
        description="Instantly detect and identify the languages used in a text. Use our language detection tool to quickly find out what languages you're dealing with. Try it for free."
        slug="/tools/language-detector"
      />
      <LanguageIdentifier />
    </Layout>
  );
};

export default index;
